import React from 'react'

import { usePopover } from 'utils/hooks/usePopover'

import { Hamburger } from './Hamburger'
import { OpenMenu } from './OpenMenu'
import { MenuBox } from './styles'

export function Menu() {
  const { ref, isOpen, togglePopover, closePopover } = usePopover()

  return (
    <MenuBox ref={ref}>
      <Hamburger onClick={togglePopover} />
      <OpenMenu
        condition={isOpen}
        closePopover={closePopover}
      />
    </MenuBox>
  )
}
