import { useQueryWithValidator } from '../../../../components/useQueryWithValidator'
import { isCancelAvailableSchema } from './schemas'
import { isCancelPendingWithdrawalsAvailable } from './service'

const getIsCancelAllWithdrawalsAvailableCacheKey = (playerId: string) => ['isCancelAllWithdrawalsAvailable', playerId]

export function useIsCancelAllWithdrawalsAvailable(playerId: string) {
  return useQueryWithValidator({
    cacheKey: getIsCancelAllWithdrawalsAvailableCacheKey(playerId),
    queryFn: isCancelPendingWithdrawalsAvailable,
    schema: isCancelAvailableSchema,
  })
}
