import styled from 'styled-components'

import { cmsContentBg, white } from '../../utils/style-utils'

interface Props {
  transparentBg?: boolean
  absolute?: boolean
}

export default styled.div<Props>`
  position: ${(p) => (p.absolute ? 'absolute' : 'fixed')};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  transform: translateZ(0px);
  backface-visibility: hidden;

  background: ${cmsContentBg};
  background-size: cover;
  background-position: center;
  mix-blend-mode: ${cmsContentBg === white ? 'screen' : 'multiply'};

  opacity: ${(p) => (p.transparentBg !== false ? '0.7' : '1')};
`
