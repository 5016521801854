import { useQuery } from '@tanstack/react-query'

import { getGames } from '../../../../containers/GamesBlock/service'
import { GamesWithCategories } from '../../../../containers/GamesBlock/types'
import { GAMES_CACHE_TIME } from './constants'
import { getGameCacheKey } from './utils'

interface Props<TSelectData> {
  select?: (data: GamesWithCategories) => TSelectData
}

export function useGamesWithCategoriesQuery<TSelectData = GamesWithCategories>({ select }: Props<TSelectData> = {}) {
  return useQuery({
    queryKey: getGameCacheKey(),
    queryFn: getGames,
    select,
    gcTime: GAMES_CACHE_TIME,
    staleTime: GAMES_CACHE_TIME,
  })
}
