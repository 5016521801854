import React, { ComponentType } from 'react'

import { FunctionComponent } from 'enzyme'
import { isTouchCached } from 'utils/device'

export function renderOnMobile<T extends Record<string, any>>(Component: ComponentType<T>): FunctionComponent<T> {
  return function RenderOnMobile(props: T) {
    if (isTouchCached()) {
      return <Component {...props} />
    }

    return null
  }
}
