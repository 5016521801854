export function getSize() {
  const width = window.innerWidth
  const height = window.innerHeight

  return { width, height }
}

export function isTouch() {
  const mobiles = /(iP(hone|ad|od)|Android|webOS|BlackBerry|Windows Phone)/i
  const isTouchEnabled: boolean =
    (!!window.navigator.userAgent.match(mobiles) || 'ontouchstart' in document.documentElement) &&
    window.navigator.userAgent.indexOf('PhantomJS') === -1 // phantomJS has touch events

  return isTouchEnabled
}

export const isTouchCached = (() => {
  let isTouchValue: boolean | undefined

  return () => {
    if (isTouchValue === undefined) {
      isTouchValue = isTouch()
    }

    return isTouchValue
  }
})()

function isKiosk() {
  const kiosks = /(BetorKiosk)/i

  return !!window.navigator.userAgent.match(kiosks)
}

export const isKioskCached = (() => {
  let isKioskValue: boolean | undefined

  return () => {
    if (isKioskValue === undefined) {
      isKioskValue = isKiosk()
    }

    return isKioskValue
  }
})()
