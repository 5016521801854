import { useQuery } from '@tanstack/react-query'
import { request } from 'utils/request'

import { SITE_MAP_URL, SITE_MAP__CACHE_TIME } from './constants'
import { siteMapSchema } from './validation'

export function useMainMenu() {
  const siteMap = useSiteMap()

  return siteMap?.mainMenu || []
}

export function useSecondaryMenu() {
  const siteMap = useSiteMap()

  return siteMap?.secondaryMenu || []
}

export function useFooterMenu() {
  const siteMap = useSiteMap()

  return siteMap?.footerMenu || []
}

export function useSiteMapMenu() {
  const siteMap = useSiteMap()

  return siteMap?.mobileMenu || []
}

function useSiteMap() {
  const { data: siteMap } = useQuery({
    queryKey: ['site-map'],
    queryFn: getSiteMap,
    gcTime: SITE_MAP__CACHE_TIME,
    staleTime: SITE_MAP__CACHE_TIME,
  })

  return siteMap
}

async function getSiteMap() {
  const { data } = await request(SITE_MAP_URL)

  return siteMapSchema.parse(data)
}
