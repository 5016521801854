import React from 'react'

import { MenuItem } from 'utils/hooks/queries/sitemap/validation'

import { MenuElementWithChildren } from './MenuItemWithChildren'
import { Link } from './styles'

interface MenuItemProps {
  item: MenuItem
  depth: number
  closePopover: () => void
}

export function MenuElement({ item, closePopover, depth }: MenuItemProps) {
  if (item.children?.length) {
    return (
      <MenuElementWithChildren
        item={item}
        closePopover={closePopover}
        depth={depth}
      />
    )
  }

  const { uri, label } = item

  return (
    <Link
      link={uri}
      onClick={closePopover}
      depth={depth}
    >
      {label}
    </Link>
  )
}
