interface Balance {
  type: string
  balance: number
  isCurrent?: boolean
}

interface Balances {
  AccountsSum: Balance
  CustomerFinancialOnlineAccount: Balance
  CustomerWithdrawalAccount: Balance
}

export enum UserType {
  unknown = 'unknown',
  anonymous = 'anonymous',
  player = 'player',
  staff = 'staff',
}

interface GenericUser {
  type: UserType
  email: string
  forFunRegistrationReq: boolean
  fullname: string
  gameFinishUrl: string | null
  id: string
  isLoginAndPlayPossible: boolean
  isPlayForFunPermitted: boolean
  isRealPlayPermitted: boolean
  noGamingSession: boolean
  reInitialize: string | null
  sendingGameFinish?: boolean
  username: string
}

interface LoggedUser extends GenericUser {
  activationRequiredTill?: string
  balances: Balances
  currentAccount?: string
  favoriteGames: string[]
  freeRounds?: string[]
  fullyActivated?: boolean
  hasOnlyRVOError?: boolean
  hasVerifiedDoc?: boolean
  isAMLFillRequired?: boolean
  isCardToVerify?: boolean
  isForFunUser?: boolean
  isLogged: boolean
  isMobileConfirmed?: boolean
  isOnlineDepositPossible?: boolean
  isOnlineWithdrawalPossible?: boolean
  isPayoutLocked?: boolean
  isStaff?: boolean
  lastPlay?: string | null
  level?: PlayerLevel
  mandatoryBreakTill?: string | null
  mandatoryLimitValue?: number
  mobile?: string
  newMessages?: number
  temporaryAccountExpired?: boolean
  unfinishedGames?: string[]
}

export type User = LoggedUser
export type PlayerLevel = string | null
