import { z } from 'zod'

const menuItemSchema = z.object({
  id: z.union([z.string(), z.number()]),
  label: z.string(),
  uri: z.string().optional(),
  rel: z.string().optional(),
  isActive: z.boolean().optional(),
  isProvider: z.boolean().optional(),
  icon: z.string().optional(),
  rows: z.number().optional(),
})

export type MenuItem = z.infer<typeof menuItemSchema> & {
  children?: MenuItem[]
}

const menuItemRecursiveSchema: z.ZodType<MenuItem> = menuItemSchema.extend({
  children: z.lazy(() => z.array(menuItemRecursiveSchema)).optional(),
})

export const siteMapSchema = z.object({
  mainMenu: z.array(menuItemRecursiveSchema),
  secondaryMenu: z.array(menuItemRecursiveSchema),
  footerMenu: z.array(menuItemRecursiveSchema),
  mobileMenu: z.array(menuItemRecursiveSchema),
  langMenu: z.array(
    z.object({
      lang: z.string(),
      active: z.boolean(),
      label: z.string(),
      url: z.string(),
    }),
  ),
  logoutUserMenu: z.array(z.any()).optional(),
})
