import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useUser } from 'containers/MainLayout/selectors'

import { GamesWithCategories, GamesWithCategoriesAndUserInfo } from '../../../../containers/GamesBlock/types'
import useGamesSlugsInOngoingTournaments from '../tournaments/useGamesSlugsInTournaments'
import { GAMES_CACHE_TIME } from './constants'
import useGamesWithCategoriesAndUserCacheKey from './useGamesWithCategoriesAndUserCacheKey'
import { useGamesWithCategoriesQuery } from './useGamesWithCategoriesQuery'
import { hydrateGamesWithUserInformation } from './utils'

interface PropsA<TSelectData> {
  select?: (data: GamesWithCategoriesAndUserInfo) => TSelectData
}

export function useGamesWithCategories<TSelectData = GamesWithCategories>({ select }: PropsA<TSelectData> = {}) {
  const user = useUser()
  const { data: games } = useGamesWithCategoriesQuery()
  const gamesSlugsInTournaments = useGamesSlugsInOngoingTournaments()
  const cacheKey = useGamesWithCategoriesAndUserCacheKey()

  return useQuery({
    queryKey: cacheKey,
    queryFn: () => hydrateGamesWithUserInformation(games!, user, gamesSlugsInTournaments),
    enabled: !!games,
    placeholderData: keepPreviousData,
    select,
    gcTime: GAMES_CACHE_TIME,
    staleTime: GAMES_CACHE_TIME,
  })
}
