import { useQueryWithValidator } from '../../../../components/useQueryWithValidator'
import { getVIPTournaments } from '../../../../containers/Tournaments/service'
import { tournamentsListSchema } from '../../../../containers/Tournaments/validator'
import { CACHE_TIME_VIP_TOURNAMENTS } from './constants'
import { getTournamentsCacheKey } from './utils'

interface Props {
  playerLevel: string
  enabled: boolean
}

export function useVIPTournaments({ playerLevel, enabled }: Props) {
  return useQueryWithValidator({
    cacheKey: getTournamentsCacheKey('vip', playerLevel),
    queryFn: () => getVIPTournaments(playerLevel),
    schema: tournamentsListSchema,
    options: {
      enabled,
      gcTime: CACHE_TIME_VIP_TOURNAMENTS,
      staleTime: CACHE_TIME_VIP_TOURNAMENTS,
    },
  })
}
