import React from 'react'

import { AgeConfirmation } from 'components/AgeConfirmation/Loadable'
import CookieInfo from 'components/Cookie'
import AnnouncementsBox from 'containers/AnnouncementsBox'
import BrowserCompatibilityDialog from 'containers/BrowserCompatibilityDialog'

import ConnectedMeta from '../../components/Meta/ConnectedMeta'
import MainRoute from '../../routes/MainRoute'
import { AnalyticsCode } from '../Analytics/AnalyticsCode'
import FlashMessagesBox from '../FlashMessagesBox'
import { GameDialogBox } from '../GameDialogBox'
import LiveChat from '../LiveChat'
import ChangeLimitInfoBox from './ChangeLimitInfoBox'
import FirstLoginDialogBox from './FirstLoginDialogBox'
import GlobalStyles from './GlobalStyles'
import PlayerBetsWinsForSkDialog from './PlayerBetsWinsForSkDialog'
import { CASINO_MODE } from './constants'

export default function App() {
  return (
    <>
      <GlobalStyles />
      <CookieInfo />
      <AnnouncementsBox />
      <BrowserCompatibilityDialog />
      <AgeConfirmation />
      <ConnectedMeta />
      <FlashMessagesBox />
      <FirstLoginDialogBox />
      <PlayerBetsWinsForSkDialog />
      <MainRoute />
      {CASINO_MODE !== 'collect' ? <ChangeLimitInfoBox /> : null}
      <AnalyticsCode />
      <LiveChat />
      <GameDialogBox />
      {/* <NewLayoutDialog /> */}
    </>
  )
}
