import React from 'react'

import withCondition from 'components/withCondition'
import { useSiteMapMenu } from 'utils/hooks/queries/sitemap'

import { MenuElements } from './MenuItems'
import { Bg, Content, Overlay } from './styles'

interface Props {
  closePopover: () => void
}

function Menu({ closePopover }: Props) {
  const menu = useSiteMapMenu()

  return (
    <Overlay>
      <Content>
        <MenuElements
          menu={menu}
          closePopover={closePopover}
          open
          depth={0}
        />
      </Content>
      <Bg onClick={closePopover} />
    </Overlay>
  )
}

export const OpenMenu = withCondition(Menu)
