import React from 'react'

import { useFooterMenu } from 'utils/hooks/queries/sitemap'

import { MenuItem } from './MenuItem'
import { FooterMenuWrapper } from './menuStyles'

export function FooterMenu() {
  const menu = useFooterMenu()

  return (
    <FooterMenuWrapper>
      {menu.map((item, id) => (
        <MenuItem
          key={id}
          item={item}
        />
      ))}
    </FooterMenuWrapper>
  )
}
