import betorRedTheme from './betorRedTheme'

const defaultBorderRadius = '0.5rem'

const btnBorderRadius = '0.25rem'

const formFieldBorderRadius = '0.5rem'

export const newBetorTheme = {
  ...betorRedTheme,

  defaultBorderRadius,

  formFieldBorderRadius,

  btnBorderRadius,
}
