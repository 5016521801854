import { User } from 'types/User'

import { GamesWithCategories, GameWithUserInformation } from '../../../../containers/GamesBlock/types'
import { GamesCacheKey } from './constants'

export function getGameCacheKey(...args: string[] | string[][]) {
  return [GamesCacheKey, ...args]
}

export const hydrateGamesWithUserInformation = (
  gamesWithCategories: GamesWithCategories,
  user: User,
  gamesSlugsInTournaments: string[],
) => {
  const unfinishedGames: string[] = user?.unfinishedGames ?? []
  const gamesWithFreeRounds: string[] = user?.freeRounds ?? []
  const favoriteGames: string[] = user?.favoriteGames ?? []

  const gamesWithUserInformation: GameWithUserInformation[] = Object.values(gamesWithCategories?.games || {}).map(
    (game) => {
      const id = Number(game.number)

      const isUnfinished = getIsUnfinished(unfinishedGames, id.toString())
      const hasFreeRounds = getHasFreeRounds(gamesWithFreeRounds, id)
      const isFavorite = getIsFavorite(favoriteGames, game.slug)
      const isInTournament = getIsInTournament(gamesSlugsInTournaments, game.slug)

      return { ...game, isUnfinished, hasFreeRounds, isFavorite, isInTournament }
    },
  )

  const keyValueGamesObject = gamesWithUserInformation.reduce(
    (accumulator, value) => ({ ...accumulator, [value.slug]: value }),
    {} as Record<string, GameWithUserInformation>,
  )

  return { ...gamesWithCategories, games: keyValueGamesObject }
}

function getIsInTournament(gamesSlugsInTournaments: string[], gameSlug: string) {
  return gamesSlugsInTournaments.some((slug) => slug === gameSlug)
}

function getIsFavorite(favoriteGames: string[], gameSlug: string) {
  return !!favoriteGames?.some((slug) => slug === gameSlug)
}

function getHasFreeRounds(gamesWithFreeRounds: string[], id: number) {
  return !!gamesWithFreeRounds?.some((number) => id.toString() === number)
}

function getIsUnfinished(unfinishedGames: string[], id: string) {
  return !!unfinishedGames?.some((number) => id === number)
}
