import UrlLink from 'components/UrlLink'
import styled from 'styled-components'
import {
  alertBoxShadow,
  bh,
  cmsContentSidebarActiveColor,
  grayDarker,
  grayDarkest,
  hexRgba,
  mainHeaderColor,
  media,
  transition,
  white,
} from 'utils/style-utils'

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  ${media.touch`
    top: unset;
    bottom: 0;
  `}
`

export const Bg = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background: ${hexRgba(grayDarker, 0.7)};

  ${media.touch`
    top: unset;
    bottom: 0;
  `}
`

export const Content = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  width: 20rem;
  height: 100dvh;
  overflow-y: auto;

  padding: 4rem 0 2rem;

  background: ${grayDarkest};
  box-shadow: ${alertBoxShadow};
  user-select: none;

  :after {
    content: '';
    display: block;

    position: fixed;
    z-index: 1;
    top: 0;
    width: 19.6rem;
    height: 4rem;

    background: ${hexRgba(grayDarkest, 0.75)};
    backdrop-filter: blur(0.5rem);
  }

  ${media.touch`
    padding: 2rem 0 4rem;
    top: unset;
    bottom: 0;

    :after {
      top: unset;
      bottom: 0;
    }
  `}
`

export const MenuBox = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ open }) => (open ? 'display: box' : 'display: none')}
`

export const Link = styled(UrlLink)<{ depth: number }>`
  color: ${mainHeaderColor};
  padding: 0.75rem 2rem;
  padding-left: ${({ depth }) => `${2 + depth}rem`};

  ${transition('all', 0.6)}

  :hover {
    color: ${white};
    text-shadow:
      0 0 8px ${white},
      0 0 16px ${white};
    background: ${grayDarker};
  }
`

export const LinkWithOpen = styled(Link)`
  position: relative;
  cursor: pointer;

  :hover {
    background: transparent;
  }
`

export const MenuItemWithChildrenBox = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ open }) => (open ? `background: rgba(255,255,255, 0.05)` : '')}
`

export const Arrow = styled.span<{ open?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: ${bh / 6}px;
  height: 100%;
  width: ${bh * 3}px;
  ${transition('transform', 0.2)};
  ${(props) => props.open && 'transform: rotate(180deg);'}

  &:after {
    content: '';
    position: relative;
    top: -${bh / 6}px;
    width: ${bh / 2}px;
    height: ${bh / 2}px;
    transform: rotate(135deg);

    border: 1px solid ${cmsContentSidebarActiveColor};
    border-left: none;
    border-bottom: none;
  }
`
