import { z } from 'zod'

export const game = z.object({
  backgroundPath: z.string().optional(),
  badges: z.array(z.string()),
  category: z.array(z.string()),
  description: z.string().optional(),
  forFunPlayUrl: z.string(),
  imagePath: z.string(),
  isHtml5Enabled: z.coerce.boolean(),
  name: z.string(),
  number: z.coerce.number(),
  languages: z.array(z.string()),
  producer: z.string(),
  provider: z.string(),
  realPlayUrl: z.string(),
  searchIndex: z.string(),
  slug: z.string(),
  topRankPosition: z.number().optional(),
})

export const gamesSchema = z.record(z.string(), game)
