import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'

const PlayerHappyHours = lazy(
  () => import(/* webpackChunkName: "PlayerHappyHours" */ '../../components/HappyHours/PlayerHappyHours/'),
)

export default function LoadablePlayerHappyHours() {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <PlayerHappyHours />
    </Suspense>
  )
}
