import styled from 'styled-components'

interface Props {
  brightness?: number
  blur?: number
  saturate?: number
}

export const DarkGlass = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  ${({ brightness, blur, saturate }) => `backdrop-filter:
    blur(${blur ?? 0.5}rem)
    brightness(${brightness ?? 0.5})
    saturate(${saturate ?? 1.5})`};
`
