import UrlLink from 'components/UrlLink'
import styled from 'styled-components'
import { alertBoxShadow, gray, grayDarkest, grayLighter, hexRgba, media, white } from 'utils/style-utils'

export const PopoverBox = styled.div`
  height: 100%;
  position: relative;
`

export const Overlay = styled.div`
  position: absolute;
  z-index: 0;

  top: 0;
  right: 0;

  ${media.sm`
    width: 100vw;
    height: 100vh;
  `}
`

export const Bg = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  ${media.sm`
    display: unset;
    width: 100vw;
    height: 100vh;

    background-image: linear-gradient(to bottom, ${hexRgba(gray, 0.7)}, 75%, ${hexRgba(gray, 0.0)});
  `}
`

export const Content = styled.div`
  position: absolute;
  z-index: 2;
  top: 4.5rem;
  right: 0rem;
  width: 16rem;
  border-radius: 0.5rem;
  overflow: hidden;
  background: ${grayDarkest};
  box-shadow: ${alertBoxShadow};

  ${media.sm`
    right: 0.5rem;
    width: calc(100vw - 1rem);

    font-size: 1.125rem;
  `}
`

export const ProfileBox = styled.div`
  margin: 0.75rem 1rem;

  ${media.sm`
    margin: 1rem 1.25rem;
  `}
`

export const Name = styled.div`
  font-weight: bold;
`

export const Email = styled.div`
  font-size: 0.75em;
  color: ${grayLighter};
`

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  margin: 0.75rem 0;
  background: ${hexRgba(white, 0.2)};
`

export const StyledLink = styled(UrlLink)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${white};
  padding: 0.25rem 1rem;

  & > img,
  & > svg {
    height: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0.75rem;
  }

  ${media.sm`
    padding: 0.3rem 1.25rem;
  `}
`
