import React from 'react'

import BallIcon from '../Icons/Ball'
import CacheBagIcon from '../Icons/CacheBag'
import CardsIcon from '../Icons/Cards'
import CourtIcon from '../Icons/Court'
import DiceIcon from '../Icons/Dice'
import GooglePlusIcon from '../Icons/GooglePlus'
import Adell from '../Icons/Logos/adell'
import Amusnet from '../Icons/Logos/amusnet'
import Apollo from '../Icons/Logos/apollo'
import Egaming from '../Icons/Logos/egaming'
import EgtDigital from '../Icons/Logos/egtDigital'
import Kajot from '../Icons/Logos/kajot'
import Pragmatic from '../Icons/Logos/pragmatic'
import Synot from '../Icons/Logos/synot'
import Tech4Bet from '../Icons/Logos/tech4bet'
import LoveIcon from '../Icons/Love'
import NewIcon from '../Icons/New'
import TwitterIcon from '../Icons/Twitter'
import Tournament from '../Icons/components/Award2'
import FacebookIcon from '../Icons/components/Facebook'
import FavoritesHeartIcon from '../Icons/components/FavoritesHeart'
import SlotsIcon from '../Icons/components/GamesCherry'
import Jackpot from '../Icons/components/GamesSeven'
import AllIcon from '../Icons/components/Grid'
import HomeIcon from '../Icons/components/Home'
import InstagramIcon from '../Icons/components/Instagram'
import SearchIcon from '../Icons/components/Search'
import StopWatchIcon from '../Icons/components/StopwatchStop'
import {
  ICON_ALL,
  ICON_BALL,
  ICON_CACHEBAG,
  ICON_CARDS,
  ICON_COURT,
  ICON_DICE,
  ICON_FACEBOOK,
  ICON_FAVORITE,
  ICON_GOOGLE_PLUS,
  ICON_HOME,
  ICON_LOVE,
  ICON_JACKPOT,
  ICON_NEW,
  ICON_SEARCH,
  ICON_SLOTS,
  ICON_STOPWATCH,
  ICON_TWITTER,
  ICON_INSTAGRAM,
  ICON_APOLLO_LOGO,
  ICON_EGAMING_LOGO,
  ICON_KAJOT_LOGO,
  ICON_PRAGMATIC_LOGO,
  ICON_SYNOT_LOGO,
  ICON_TOURNAMENT,
  ICON_AMUSNET_LOGO,
  ICON_EGT_DIGITAL_LOGO,
  ICON_TECH4BET_LOGO,
  ICON_ADELL_LOGO,
} from './constants'

export const iconMap = {
  [ICON_ALL]: AllIcon,
  [ICON_BALL]: BallIcon,
  [ICON_CACHEBAG]: CacheBagIcon,
  [ICON_CARDS]: CardsIcon,
  [ICON_COURT]: CourtIcon,
  [ICON_DICE]: DiceIcon,
  [ICON_FAVORITE]: FavoritesHeartIcon,
  [ICON_SLOTS]: SlotsIcon,
  [ICON_TOURNAMENT]: Tournament,
  [ICON_LOVE]: LoveIcon,
  [ICON_JACKPOT]: Jackpot,
  [ICON_NEW]: NewIcon,
  [ICON_SEARCH]: SearchIcon,
  [ICON_HOME]: HomeIcon,
  [ICON_STOPWATCH]: StopWatchIcon,
  [ICON_FACEBOOK]: FacebookIcon,
  [ICON_INSTAGRAM]: InstagramIcon,
  [ICON_TWITTER]: TwitterIcon,
  [ICON_GOOGLE_PLUS]: GooglePlusIcon,
  [ICON_APOLLO_LOGO]: Apollo,
  [ICON_EGAMING_LOGO]: Egaming,
  [ICON_KAJOT_LOGO]: Kajot,
  [ICON_PRAGMATIC_LOGO]: Pragmatic,
  [ICON_SYNOT_LOGO]: Synot,
  [ICON_AMUSNET_LOGO]: Amusnet,
  [ICON_EGT_DIGITAL_LOGO]: EgtDigital,
  [ICON_ADELL_LOGO]: Adell,
  [ICON_TECH4BET_LOGO]: Tech4Bet,
  default: () => <></>,
}

export type IconKey = keyof typeof iconMap
