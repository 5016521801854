import { iconMap, IconKey } from 'components/IconLink/iconMap'
import { z } from 'zod'

export const gameInCategorySchema = z.object({
  position: z.number(),
  size: z.number(),
  slug: z.string(),
  topRankPosition: z.number(),
})

export const categorySchema = z.object({
  id: z.string(),
  icon: z.enum(Object.keys(iconMap) as [IconKey]),
  isActive: z.boolean(),
  isProvider: z.boolean().default(false),
  games: z.array(gameInCategorySchema),
  label: z.string(),
  rel: z.string(),
  rows: z.number(),
  slug: z.string(),
  uri: z.string(),
})

export const categoriesSchema = z.array(categorySchema)
