import React, { ComponentType } from 'react'

import { isTouchCached } from 'utils/device'

export function renderOnDesktop<T extends Record<string, any>>(Component: ComponentType<T>) {
  return function RenderOnDesktop(props: T) {
    if (isTouchCached()) {
      return null
    }

    return <Component {...props} />
  }
}
