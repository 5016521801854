import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import ArchivalTournaments from '../containers/ArchivalTournaments/LoadableArchivalTournaments'
import { LoadableArchiveHappyHours } from '../containers/ArchiveHappyHours/LoadableArchiveHappyHours'
import CollectAccessGrantPage from '../containers/CollectAccessGrantPage/Loadable'
import CollectTransferRequestPage from '../containers/CollectTransferRequestPage/Loadable'
import ContinueVltRegistrationPage from '../containers/ContinueVltRegistrationPage/Loadable'
import HappyHourListLoadable from '../containers/HappyHours/List/Loadable'
import IdentityDocumentRegisterPage from '../containers/IdentityDocumentRegisterPage/Loadable'
import JackpotsListLoadable from '../containers/Jackpots/List/Loadable'
import { MainLayout } from '../containers/MainLayout'
import OAuthRegistrationForm from '../containers/OAuthRegistrationForm/Loadable'
import PaymentDetailsPage from '../containers/PaymentDetailsPage/Loadable'
import RegisterChoicePage from '../containers/RegisterChoicePage/Loadable'
import ResetPasswordRequestPage from '../containers/ResetPasswordRequestPage/Loadable'
import TournamentsLoadable from '../containers/Tournaments/Loadable'
import CMSBoxLayoutSwitch from './CMSBoxLayoutSwitch'
import LoadableHomePage from './Home/Loadable'

export default function MainLayoutSwitch() {
  return (
    <MainLayout>
      <Switch>
        <Route
          exact
          path={['/:lang?', '/:lang/games/:categorySlug?']}
          component={LoadableHomePage}
        />
        <Route
          exact
          path="/:lang/register/identity-document"
          component={IdentityDocumentRegisterPage}
        />
        <Route
          path="/:lang/register/choice"
          component={RegisterChoicePage}
        />
        <Route
          path="/:lang/oauth/register"
          component={OAuthRegistrationForm}
        />
        <Route
          path="/:lang/profile/oauth/v2/access-grant"
          component={CollectAccessGrantPage}
        />
        <Route
          path="/:lang/continue-vlt-registration"
          component={ContinueVltRegistrationPage}
        />
        <Route
          path="/:lang/resetting/request"
          component={ResetPasswordRequestPage}
        />
        <Route
          path="/:lang/payment/canceled/:code"
          component={PaymentDetailsPage}
        />
        <Route
          path="/:lang/payment/finished/:code"
          component={PaymentDetailsPage}
        />
        <Route
          path="/:lang/payment/request/:transferHash"
          component={PaymentDetailsPage}
        />
        <Route
          path="/:lang/payment/transfer/request/:transferHash"
          component={CollectTransferRequestPage}
        />
        <Route
          path="/:lang/payment/:hash([A-Z0-9]{15,32})"
          component={PaymentDetailsPage}
        />
        <Route
          path="/:lang/netera/confirmation/:confirmationToken"
          component={OAuthRegistrationForm}
        />
        <Route
          path="/:lang/tournament/:id"
          component={TournamentsLoadable}
        />
        <Route
          path="/:lang/tournaments"
          component={TournamentsLoadable}
        />
        <Route
          path="/:lang/archival-tournaments"
          component={ArchivalTournaments}
        />
        <Route
          path="/:lang/jackpots"
          component={JackpotsListLoadable}
        />
        <Route
          path="/:lang/happy-hours"
          component={HappyHourListLoadable}
        />
        <Route
          path="/:lang/archival-happy-hours"
          component={LoadableArchiveHappyHours}
        />
        <Route path="/content/:lang/init">
          <Redirect to="/" />
        </Route>
        <Route component={CMSBoxLayoutSwitch} />
      </Switch>
    </MainLayout>
  )
}
