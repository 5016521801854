import { z } from 'zod'

import { categoriesSchema } from './schemas/categoriesSchema'
import { gamesSchema } from './schemas/gamesSchema'

export const gamesWithCategoriesSchema = z.object({
  defaultCategory: z.string(),
  mainCategory: z.string(),
  categories: categoriesSchema,
  games: gamesSchema,
})
