import React from 'react'

import { useIsNewLayout } from 'containers/App/NewLayout/useIsNewLayout'
import { useSecondaryMenu } from 'utils/hooks/queries/sitemap'

import { MenuItem } from './MenuItem'
import { SecondaryMenuWrapper } from './menuStyles'

export function SecondaryMenu() {
  const isNewLayout = useIsNewLayout()
  const menu = useSecondaryMenu()

  if (!isNewLayout) {
    return null
  }

  return (
    <SecondaryMenuWrapper>
      {menu.map((item, id) => (
        <MenuItem
          key={id}
          item={item}
        />
      ))}
    </SecondaryMenuWrapper>
  )
}
