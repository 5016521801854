import React from 'react'

import { fromJS } from 'immutable'
import useGame from 'utils/hooks/queries/games/useGame'

import GameDialog from '../../components/GameDialog'

interface Props {
  slug: string
  onClose: () => void
}

export function GameDialogWithData({ slug, onClose }: Props) {
  const { data: game, isPending } = useGame({ slug })

  if (isPending || !game) {
    return null
  }

  return (
    <GameDialog
      game={fromJS(game)}
      onClose={onClose}
    />
  )
}
