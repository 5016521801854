import { LANG } from '../../../../containers/App/constants'
import { post, request } from '../../../request'
import { DepositPromoCodeValidationRequestBody } from './types'

const CANCEL_PENDING_WITHDRAWALS = `/${LANG}/payment/cancel-all-withdrawals`
const IS_CANCEL_PENDING_WITHDRAWALS_AVAILABLE = `/${LANG}/payment/is-cancel-all-available`
const IS_DEPOSIT_PROMO_CODE_VALID = `/${LANG}/payment/deposit_promo_code_check`

export async function cancelPendingWithdrawals(): Promise<void> {
  await post(CANCEL_PENDING_WITHDRAWALS)
}

export async function isCancelPendingWithdrawalsAvailable(): Promise<unknown> {
  const { data } = await request(IS_CANCEL_PENDING_WITHDRAWALS_AVAILABLE)

  return data
}

export async function isPromoCodeValid(body?: DepositPromoCodeValidationRequestBody) {
  const { data } = await post(IS_DEPOSIT_PROMO_CODE_VALID, { body: JSON.stringify(body) })

  return data
}
