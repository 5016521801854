import React, { FunctionComponent } from 'react'

import { IconProps } from 'components/Icons/types'

import { IconKey, iconMap } from './iconMap'

interface Props extends IconProps {
  icon: IconKey | FunctionComponent
}

export function Icon({ icon, ...props }: Props) {
  const IconComponent = typeof icon === 'function' ? icon : iconMap[icon] || iconMap.default

  return (
    <IconComponent
      data-icon={icon}
      {...props}
    />
  )
}
