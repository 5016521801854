import React from 'react'

import { useMainMenu } from 'utils/hooks/queries/sitemap'

import { MenuItem } from './MenuItem'
import { MainMenuBox } from './styles'

export function MainMenu() {
  const menu = useMainMenu()

  return (
    <MainMenuBox>
      {menu.map((item, id) => (
        <MenuItem
          key={id}
          item={item}
        />
      ))}
    </MainMenuBox>
  )
}
