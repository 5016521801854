import { useSelector } from 'react-redux'

import { createSelector } from 'reselect'
import { Store } from 'types/store'

import { ACCOUNT_SUM } from '../App/constants'

const selectStore = (state: Store) => state
export const selectGlobal = (state: Store) => state.get('global')

export const makeSelectMainMenu = () => createSelector(selectGlobal, (global) => global.get('mainMenu').get('items'))

export const makeSelectFooterMenu = () =>
  createSelector(selectGlobal, (global) => global.getIn(['footerMenu', 'items']))

export const makeSelectSecondaryMenuItems = () =>
  createSelector(selectGlobal, (global) => global.getIn(['secondaryMenu', 'items']))

export const makeSelectMobileMenuItems = () =>
  createSelector(selectGlobal, (global) => global.getIn(['mobileMenu', 'items']))

export const makeSelectMobileMenuOpen = () =>
  createSelector(selectGlobal, (global) => global.getIn(['mobileMenu', 'open']))

export const makeSelectLangMenu = () => createSelector(selectGlobal, (global) => global.getIn(['langMenu', 'items']))

export const makeSelectLangMenuOpen = () => createSelector(selectGlobal, (global) => global.getIn(['langMenu', 'open']))

export const makeSelectUserMenuOpen = () =>
  createSelector(selectGlobal, (global) => global.getIn(['mainMenu', 'userMenuOpen']))

export const makeSelectLoading = () => createSelector(selectGlobal, (global) => global.get('loading'))

export const makeSelectReloading = () => createSelector(selectGlobal, (global) => global.get('reloading'))

export const makeSelectChunkLoading = () => createSelector(selectGlobal, (global) => global.get('chunkLoading'))

export const makeSelectError = () => createSelector(selectGlobal, (global) => global.get('error'))

export const useSelectError = () => useSelector(makeSelectError())

export const makeSelectFirstLoading = () => createSelector(selectGlobal, (global) => global.get('firstLoad'))

export const makeSelectUser = () => createSelector(selectGlobal, (global) => global.get('user'))
export const useUser = () => useSelector(makeSelectUser()).toJS()

export const makeSelectIsLogged = () => createSelector(selectGlobal, (global) => global.getIn(['user', 'isLogged']))

const makeSelectUserType = () => createSelector(selectGlobal, (global) => global.getIn(['user', 'type']))
export const useSelectUserType = () => useSelector(makeSelectUserType())

export const makeSelectUserActivationRequiredTill = () =>
  createSelector(selectGlobal, (global) => global.getIn(['user', 'activationRequiredTill']))

export const makeSelectUserTemporaryAccountExpired = () =>
  createSelector(selectGlobal, (global) => global.getIn(['user', 'temporaryAccountExpired']))

export const makeSelectIsAMLFillRequired = () =>
  createSelector(selectGlobal, (global) => global.getIn(['user', 'isAMLFillRequired']))

export const makeSelectIsUserFullyActivated = () =>
  createSelector(selectGlobal, (global) => global.getIn(['user', 'fullyActivated']))

export const makeSelectUserBalances = () =>
  createSelector(selectGlobal, (global) => global && global.getIn(['user', 'balances']))

export const makeSelectUserAccountSum = () =>
  createSelector(selectGlobal, (global) => global && global.getIn(['user', 'balances', ACCOUNT_SUM, 'balance']))
export const useUserAccountSum = () => useSelector(makeSelectUserAccountSum())

export const makeSelectGameFinishUrl = () =>
  createSelector(selectGlobal, (global) => global && global.getIn(['user', 'gameFinishUrl']))

export const makeSelectSendingGameFinish = () =>
  createSelector(selectGlobal, (global) => global && global.getIn(['user', 'sendingGameFinish']))

export const makeSelectReInitialize = () =>
  createSelector(selectGlobal, (global) => global && global.getIn(['user', 'reInitialize']))

export const makeSelectUserFavoriteGames = () =>
  createSelector(selectStore, (global) => global.getIn(['global', 'user', 'favoriteGames']))

export const makeSelectPlayerLevel = () =>
  createSelector(selectGlobal, (global) => global.getIn(['user', 'level']) ?? null)

export const makeSelectBranch = () => createSelector(selectGlobal, (global) => global.get('branch'))

export const makeSelectAnnouncements = () => createSelector(selectGlobal, (global) => global.get('announcements'))

export const makeSelectCookies = () => createSelector(selectGlobal, (global) => global.get('cookie'))
