import React from 'react'

import { Icon } from 'components/IconLink/Icon'
import type { IconKey } from 'components/IconLink/iconMap'
import type { MenuItem } from 'utils/hooks/queries/sitemap/validation'

import { StyledUrlLink } from './styles'

interface Props {
  item: MenuItem
}

export function MenuItem({ item: { uri, icon } }: Props) {
  return (
    <StyledUrlLink url={uri}>
      <Icon icon={icon as IconKey} />
    </StyledUrlLink>
  )
}
