import styled from 'styled-components'

import { headerHeight } from '../../styles'

export const BottomAppBarBox = styled.header`
  display: flex;
  width: 100%;
  height: ${headerHeight};
  position: fixed;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  z-index: 10;
`

export const Links = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  gap: 1rem;

  > * {
    flex: 1;
  }
`
