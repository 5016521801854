import React from 'react'

import { MenuItem } from 'utils/hooks/queries/sitemap/validation'

import { MenuElement } from './MenuItem'
import { MenuBox } from './styles'

interface MenuItemsProps {
  menu: MenuItem[]
  closePopover: () => void
  open?: boolean
  depth: number
}

export function MenuElements({ menu, closePopover, open, depth }: MenuItemsProps) {
  return (
    <MenuBox open={open}>
      {menu.map((item, index) => (
        <MenuElement
          item={item}
          closePopover={closePopover}
          key={index}
          depth={depth}
        />
      ))}
    </MenuBox>
  )
}
