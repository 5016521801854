import Container from 'components/Container'
import styled from 'styled-components'

import { headerHeight } from '../styles'

export const AppBarBox = styled.header`
  width: 100%;
  height: ${headerHeight};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`

export const StyledContainer = styled(Container)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const EmptySpace = styled.div`
  flex: 1;
`

export const FixedFix = styled.div`
  height: ${headerHeight};
`
