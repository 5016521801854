import React, { useState } from 'react'

import { MenuItem } from 'utils/hooks/queries/sitemap/validation'

import { MenuElements } from './MenuItems'
import { Arrow, LinkWithOpen, MenuItemWithChildrenBox } from './styles'

interface MenuItemProps {
  item: MenuItem
  depth: number
  closePopover: () => void
}

export function MenuElementWithChildren({ item: { label, children }, depth, closePopover }: MenuItemProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MenuItemWithChildrenBox open={open}>
        <LinkWithOpen
          depth={depth}
          onClick={() => setOpen((o) => !o)}
        >
          <span>{label}</span>
          <Arrow open={open} />
        </LinkWithOpen>

        <MenuElements
          menu={children!}
          closePopover={closePopover}
          open={open}
          depth={depth + 1}
        />
      </MenuItemWithChildrenBox>
    </>
  )
}
