import { useUser } from 'containers/MainLayout/selectors'

import useGamesSlugsInOngoingTournaments from '../tournaments/useGamesSlugsInTournaments'
import { getGameCacheKey } from './utils'

export default function useGamesWithCategoriesAndUserCacheKey() {
  const user = useUser()
  const gamesSlugsInTournaments = useGamesSlugsInOngoingTournaments()
  const gamesCacheKey = getGameCacheKey(
    user?.favoriteGames || [],
    user?.freeRounds || [],
    user?.unfinishedGames || [],
    gamesSlugsInTournaments,
  )

  return gamesCacheKey
}
