import React, { lazy, Suspense } from 'react'

import CenteredLoader from '../../components/Loader/CenteredLoader'

const ArchiveHappyHoursPage = lazy(() => import(/* webpackChunkName: "ArchiveHappyHoursPage" */ '.'))

export function LoadableArchiveHappyHours() {
  return (
    <Suspense fallback={<CenteredLoader />}>
      <ArchiveHappyHoursPage />
    </Suspense>
  )
}
