import React, { lazy } from 'react'
import { useSelector } from 'react-redux'

import withLazyLoad from 'components/withLazyLoader'
import { AGE_CONFIRMATION_REQUIRED } from 'containers/App/constants'
import { makeSelectIsLogged } from 'containers/MainLayout/selectors'

const Component = withLazyLoad(
  lazy(() => import(/* webpackChunkName: "AgeConfirmation" */ './index')),
  () => <></>,
)

export function AgeConfirmation() {
  const isLogged = useSelector(makeSelectIsLogged())

  return <Component condition={!isLogged && AGE_CONFIRMATION_REQUIRED} />
}
