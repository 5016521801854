import styled from 'styled-components'
import { mainHeaderColor, transition, white } from 'utils/style-utils'

export const HamburgerBox = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;

  height: 100%;
  width: 4rem;

  cursor: pointer;

  & > div {
    height: 2px;
    border-radius: 2px;
    width: 1.25rem;

    background-color: ${mainHeaderColor};
    box-shadow: 0 0 0 ${white};
    ${transition('all', 0.6)}
  }

  :hover {
    & > div {
      background-color: ${white};
      box-shadow:
        0 0 4px ${white},
        0 0 16px ${white},
        0 0 32px ${white},
        0 0 64px ${white};
    }
  }
`

export const MenuBox = styled.div`
  height: 100%;
  position: relative;
`
