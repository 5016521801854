import { useCallback, useState } from 'react'

import { setCookie } from 'utils/cookies'
import { LAYOUT_FROM_COOKIE } from 'utils/layout'

import { cookieKey, Layout } from './constant'

export function useLayout() {
  const [layout, setLayoutState] = useState(LAYOUT_FROM_COOKIE)

  const setLayout = useCallback((newLayout: Layout) => {
    setCookie(cookieKey, newLayout)
    setLayoutState((currentLayout) => {
      if (currentLayout !== newLayout) {
        window.location.reload()
      }

      return newLayout
    })
  }, [])

  const setNewLayout = useCallback(() => {
    setLayout(Layout.NEW)
  }, [setLayout])

  const setOldLayout = useCallback(() => {
    setLayout(Layout.OLD)
  }, [setLayout])

  return { layout, setNewLayout, setOldLayout }
}
