import React from 'react'

import { HamburgerBox } from './styles'

interface Props {
  onClick: () => void
}

export function Hamburger({ onClick }: Props) {
  return (
    <HamburgerBox onClick={onClick}>
      <div />
      <div />
      <div />
    </HamburgerBox>
  )
}
